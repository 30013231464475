import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "../../assets/svg/AllSvg";
import strings from "../../components/localization";

const Policy = () => {
  return (
    <div className="">
      <div className="text-black font-rajdhani py-5 mt-4">
        <div className="container sm:px-6 px-4 mx-auto space-y-5">
          <Link className="inline-block" to={"/"}>
            <Logo fillClass={"fill-primary"} />
          </Link>
          <h1 className="text-4xl font-semibold text-primary  mb-4">
            {strings?.cookie_policy?.policy}
          </h1>
          <div className="space-y-3 text-sm">
            <p>
            {strings?.cookie_policy?.policy1}
            </p>
            <p>
            {strings?.cookie_policy?.policy2}
            </p>
            <p>
            {strings?.cookie_policy?.policy3}
            </p>
            <p>
            {strings?.cookie_policy?.policy4}
            </p>
            <p>
            {strings?.cookie_policy?.policy5}
            </p>
          </div>
          <h2 className="text-2xl font-semibold  text-primary mb-4">
          {strings?.cookie_policy?.policy6}
          </h2>
          <div className="space-y-3">
            <div className="space-y-2">
              <h3 className="text-xl font-semibold  text-primary">{strings?.cookie_policy?.policy7}</h3>
              <p className="text-sm">
              {strings?.cookie_policy?.policy8}
              </p>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy9}
              </p>
              <p className="text-xs font-semibold  text-primary">
              {strings?.cookie_policy?.policy10}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy11}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy12}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy13}
                <Link
                  className="text-primary"
                  to={"https://www.iubenda.com/privacy-policy/36700132"}
                >
                 {strings?.cookie_policy?.policy14}
                </Link>
              </p>
              <p className="text-sm">{strings?.cookie_policy?.policy15}</p>
              <ul className="list-disc pl-10">
                <li>{strings?.cookie_policy?.policy16}</li>
              </ul>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold  text-primary">
              {strings?.cookie_policy?.policy17}
              </h3>
              <p className="text-sm">
              {strings?.cookie_policy?.policy18}
              </p>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy19}
              </p>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy20}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy21}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy22}
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold  text-primary">
              {strings?.cookie_policy?.policy23}
              </h3>
              <p className="text-sm">
              {strings?.cookie_policy?.policy24}
              </p>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy25}
              </p>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy26}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy27}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy28}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy29}
                <Link
                  className="text-primary"
                  to={"https://business.safety.google/privacy/"}
                >
                  {strings?.cookie_policy?.policy30}
                </Link>
              </p>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy31}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy32}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy33}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy34}
                <Link
                  className="text-primary"
                  to={"https://www.facebook.com/privacy/policy/"}
                >
                  {strings?.cookie_policy?.policy35}
                </Link>
                -{" "}
                <Link
                  className="text-primary"
                  to={"https://www.facebook.com/help/247395082112892"}
                >
                  {strings?.cookie_policy?.policy36}
                </Link>
              </p>
              <p className="text-sm">{strings?.cookie_policy?.policy37}</p>
              <ul className="list-disc pl-10">
                <li>{strings?.cookie_policy?.policy38}</li>
              </ul>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold  text-primary">
              {strings?.cookie_policy?.policy39}
              </h3>
              <p className="text-sm">
              {strings?.cookie_policy?.policy40}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy41}
              </p>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy42}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy43}
              </p>
              <ul className="list-disc pl-10">
                <li>
                {strings?.cookie_policy?.policy44}
                </li>
                <li>{strings?.cookie_policy?.policy45}</li>
                <li>{strings?.cookie_policy?.policy46}</li>
              </ul>
              <p className="text-sm">
              {strings?.cookie_policy?.policy47}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy48}
              </p>
              <ul className="list-disc pl-10">
                <li>
                  <Link
                    className="text-primary"
                    to={
                      "https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies"
                    }
                  >
                    {strings?.cookie_policy?.policy49}
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-primary"
                    to={
                      "https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                    }
                  >
                    {strings?.cookie_policy?.policy50}
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-primary"
                    to={
                      "https://support.apple.com/en-in/guide/safari/sfri11471/mac"
                    }
                  >
                    {strings?.cookie_policy?.policy51}
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-primary"
                    to={
                      "https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d"
                    }
                  >
                    {strings?.cookie_policy?.policy52}
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-primary"
                    to={
                      "https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d"
                    }
                  >
                    {strings?.cookie_policy?.policy53}
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-primary"
                    to={
                      "https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing"
                    }
                  >
                    {strings?.cookie_policy?.policy54}
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-primary"
                    to={
                      "https://help.opera.com/en/latest/web-preferences/#cookies"
                    }
                  >
                    {strings?.cookie_policy?.policy55}
                  </Link>
                </li>
              </ul>
              <p className="text-sm">
              {strings?.cookie_policy?.policy56}
              </p>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy57}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy58}
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold  text-primary">
              {strings?.cookie_policy?.policy59}
              </h3>
              <div className="text-sm">
                <p>{strings?.cookie_policy?.policy60}</p>
                <p>{strings?.cookie_policy?.policy61}</p>
                <p>{strings?.cookie_policy?.policy62}</p>
                <p>{strings?.cookie_policy?.policy63}</p>
                <p>{strings?.cookie_policy?.policy64}</p>
                <p>{strings?.cookie_policy?.policy65}</p>
              </div>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy66}
                <span className="text-black font-normal">
                {strings?.cookie_policy?.policy67}
                </span>
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy68}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy69}
              </p>
            </div>
            <div className="space-y-2">
              <h3 className="text-xl font-semibold  text-primary">
              {strings?.cookie_policy?.policy70}
              </h3>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy71}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy72}
              </p>
              <p className="font-semibold  text-primary">{strings?.cookie_policy?.policy73}</p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy74}
              </p>
              <p className="font-semibold  text-primary">{strings?.cookie_policy?.policy75}</p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy76}
              </p>
              <p className="font-semibold  text-primary">{strings?.cookie_policy?.policy77}</p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy78}
              </p>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy79}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy80}
              </p>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy81}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy82}
              </p>
              <p className="font-semibold  text-primary">{strings?.cookie_policy?.policy83}</p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy84}
              </p>
              <p className="font-semibold  text-primary">{strings?.cookie_policy?.policy85}</p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy86}
              </p>
              <p className="font-semibold  text-primary">
              {strings?.cookie_policy?.policy87}
              </p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy88}
              </p>
              <p className="font-semibold  text-primary">{strings?.cookie_policy?.policy89}</p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy90}
              </p>
              <p className="font-semibold  text-primary">{strings?.cookie_policy?.policy91}</p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy92}
              </p>
              <hr className="border" />
              <p className="font-semibold  text-primary">{strings?.cookie_policy?.policy93}</p>
              <p className="text-sm">
              {strings?.cookie_policy?.policy94}
              </p>
              <p className="text-sm">{strings?.cookie_policy?.policy95}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Policy;
