import React, { useEffect } from "react";
import { LanguageIcon, Logo, Logo2, Logo3 } from "../assets/svg/AllSvg";
import strings from "../components/localization";
import { Link as ScrollLink } from "react-scroll";
import { Link, useLocation } from "react-router-dom";

const Header = ({ setSelectedLanguage, selectedLanguage }) => {
  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 90) {
        document.querySelector("header").classList.add("header-scroll") ;
      } else {
        document.querySelector("header").classList.remove("header-scroll");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const countries = [
    { value: "de", label: "De" },
    { value: "en", label: "En" },
  ];
  return (
    <>
      <header
        className={`fixed top-0 left-0 w-full z-20 ${
          location.pathname === "/" ? "" : "bg-primary "
        }`}
      >
        <div className="container flex justify-between items-center font-dmmonoFont sm:py-6 py-7 sm:px-6 px-4 mx-auto ">
          <Link className="inline-block sm:w-64 w-20" to={"/"}>
            <Logo2
              className="w-full fill-lightBackgroundDefault hidden sm:block"
              fillClass={"logo_color"}
            />
            <Logo3
              className="w-full fill-lightBackgroundDefault sm:hidden "
              fillClass={"logo_color"}
            />
          </Link>
          <div className="flex  items-center gap-5">
          {location.pathname === "/" && <>
            <ScrollLink
              to="form-content"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="sm:block scrollLink font-roboto font-medium text-sm rounded-md no-underline leading-5 cursor-pointer"
            >
              Hello
            </ScrollLink>
            <ScrollLink
              to="vision"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              className="sm:block scrollLink font-roboto font-medium text-sm rounded-md no-underline leading-5 cursor-pointer ml-2"
            >
              Vision
            </ScrollLink>
          </>
          }

            <div className="relative flex items-center 2xl:-mr-0 xl:-mr-0 lg:-mr-0 md:-mr-0 -mr-2 2xl:ml-4 xl:ml-4 ml-1">
              <div className="sm:block ">
                <LanguageIcon
                  fillClass={"fill-color"}
                  className="2xl:w-[20px] xl:w-[20px] lg:w-[18px] md:w-[18px] w-4 mr-2"
                />
              </div>
              <div className="flex items-center">
                {countries?.map((element, index) => {
                  return (
                    <p
                      key={index} 
                      className={`${
                        selectedLanguage === element.value
                          ? "text-black "
                          : "text-gray-500"
                      } cursor-pointer text-sm font-semibold`}
                      onClick={() => {
                        setSelectedLanguage(element.value);
                        strings.setLanguage(element.value);
                      }}
                    >
                      {index === 0 ? element.label : `|${element.label}`}
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
