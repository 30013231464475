import React from "react";
import { Link } from "react-router-dom";
import strings from "../components/localization";
import { FooterLogo } from "../assets";

const Footer = () => {
  return (
    <>
      <div>
        <footer className="lg:pt-9 lg:pb-6 md:py-6 py-6">
          <div className="container max-w-[750px] px-4 mx-auto">
            <div className="lg:flex items-center justify-between ">
              <div className="flex gap-2 font-rajdhani md:items-center items-start">
                <a href="/">
                  <img src={FooterLogo} alt="logo" className="w-4 h-4" />
                </a>
                <div className="lg:flex items-center md:flex font-rajdhani md:gap-1 md:space-y-0 ">
                  <div className="flex gap-1">
                    <p className="font-semibold text-black xl:text-[10.4px] md:text-[11px] text-xs flex gap-1 !leading-[18px]">
                      {strings?.footer?.footerDescription}
                    </p>
                    <Link
                      to="/"
                      className="font-semibold text-black xl:text-[10.4px] md:text-[11px] text-xs !leading-[18px]"
                    >
                      {strings?.footer?.footerDescription1}
                    </Link>
                    <p className="font-semibold text-black xl:text-[10.4px] md:text-[11px] text-xs flex gap-1 !leading-[18px]">
                      {strings?.footer?.footerDescription2}
                    </p>
                  </div>

                  <p className="lg:flex items-center !leading-[18px] gap-1">
                    {" "}
                    <Link
                      to="/terms-and-conditions"
                      className="font-semibold text-black xl:text-[10.4px] md:text-[11px] text-xs !leading-[18px]"
                    >
                      {strings?.footer?.footerDescription3}
                    </Link>{" "}
                    <Link
                      to="/privacy-policy"
                      className="font-semibold text-black xl:text-[10.4px] md:text-[11px] text-xs !leading-[18px]"
                    >
                      {strings?.footer?.footerDescription4}
                    </Link>{" "}
                  </p>
                </div>
              </div>
              <div className="flex items-center lg:gap-0 md:gap-2 gap-2 xl:mt-0 lg:mt-0 md:mt-2 mt-4 font-rajdhani md:pl-0 pl-6">
                <div className="w-4 h-4 lg:hidden md:block hidden" />
                <p className="lg:text-center font-semibold text-black xl:text-[10.4px] md:text-[11px] text-xs !leading-4">
                  {strings?.footer?.footerDescription5}{" "}
                  <Link
                    to="https://www.instagram.com/sayhelloebbi"
                    className="font-semibold text-black xl:text-[10.4px] md:text-[11px] text-xs"
                  >
                    {strings?.footer?.instagramText}
                  </Link>{" "}
                  {strings?.footer?.footerDescription6}{" "}
                  <Link
                    to="https://www.linkedin.com/company/102427425"
                    className="font-semibold text-black xl:text-[10.4px] md:text-[11px] text-xs"
                  >
                    {strings?.footer?.linkedInText}
                  </Link>{" "}
                </p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
