import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DefaultLayout from "../layouts";
import Home from "../pages/home";
import strings from "../components/localization";
import TermsAndConditions from "../pages/termsAndConditions";
import ScrollToTop from "../commons/ScrollToTop";
import Policy from "../pages/policy";
import PrivacyPolicy from "../pages/privacyPolicy";

const AppRoutes = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    strings.setLanguage(selectedLanguage);
  }, [selectedLanguage]);
  return (
    <>
      <ScrollToTop />
      <ToastContainer position="bottom-right" theme="colored" />
      <Routes>
        <Route
          path="/"
          element={
            <DefaultLayout
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
          }
        >
          {/* Home */}
          <Route index exact element={<Home />} />
          <Route exact path="/cookie-policy" element={<Policy />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            exact
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
        </Route>
      </Routes>
    </>
  );
};

export default AppRoutes;
