import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosApi } from "../../helpers/AxiosApi";
import initialStates from "./state";

export const submitInquiry = createAsyncThunk(
    "submitInquiry",
    async (data, { rejectWithValue }) => {
      try {
        const response = await AxiosApi.post("submit-inquiry", data);
        return response.data;
      } catch (error) {
        if (!error) {
          throw error.response;
        }
        return rejectWithValue(error.response.data);
      }
    }
  );

  const submitInquirySlice = createSlice({
    name: "submitInquiry",
    initialState: initialStates,
    extraReducers: {
      [submitInquiry.pending]: (state) => {
        state.loading = true;
      },
      [submitInquiry.fulfilled]: (state, action) => {
        state.loading = false;
        state.formStatus.data = action.payload;
        state.error = false;
      },
      [submitInquiry.rejected]: (state) => {
        state.loading = false;
        state.error = true;
      },       
    },    
  });
  const { reducer } = submitInquirySlice;
  export default reducer;
  