import React from "react";
import { Link } from "react-router-dom";
import strings from "../../components/localization";
import { Logo } from "../../assets/svg/AllSvg";

const TermsAndConditions = () => {
  return (
    <div className="">
      <div className="text-black font-rajdhani py-5 mt-4">
        <div className="container sm:px-6 px-4 mx-auto space-y-5">
          <Link className="inline-block" to={"/"}>
            <Logo fillClass={"fill-primary"} />
          </Link>
          <h1 className="text-4xl font-semibold text-primary  mb-4">
            {strings?.terms_and_conditions?.tac}
          </h1>
          <div className="space-y-3 text-sm">
            <p className="text-sm">{strings?.terms_and_conditions?.tac1}</p>
            <ul className="list-disc pl-10">
              <li>{strings?.terms_and_conditions?.tac2}</li>
              <li>{strings?.terms_and_conditions?.tac3}</li>
            </ul>
            <p className="text-sm">{strings?.terms_and_conditions?.tac4}</p>
          </div>
          <div className="space-y-3">
            <p className="text-sm">{strings?.terms_and_conditions?.tac5}</p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac6}</p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac7}</p>
            <div className="text-sm">
              <p>{strings?.terms_and_conditions?.tac8}</p>
              <p>{strings?.terms_and_conditions?.tac9}</p>
              <p>{strings?.terms_and_conditions?.tac10}</p>
              <p>{strings?.terms_and_conditions?.tac11}</p>
              <p>{strings?.terms_and_conditions?.tac12}</p>
            </div>
            <p className="font-semibold  text-primary">
              {strings?.terms_and_conditions?.tac13}{" "}
              <span className="text-black font-normal">
                {strings?.terms_and_conditions?.tac14}
              </span>
            </p>
          </div>
          <div className="space-y-3">
            <div className="space-y-2">
              <h3 className="text-xl font-semibold  text-primary">
                {strings?.terms_and_conditions?.tac15}
              </h3>
              <ul className="list-disc pl-10">
                <li>{strings?.terms_and_conditions?.tac16}</li>
                <li>{strings?.terms_and_conditions?.tac17}</li>
              </ul>
            </div>
            <hr className="border" />
            <div className="space-y-2">
              <h3 className="text-xl font-semibold  text-primary">
                {strings?.terms_and_conditions?.tac18}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac19}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac20}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac21}</p>
              <ul className="list-disc pl-10">
                <li>{strings?.terms_and_conditions?.tac22}</li>
                <li>{strings?.terms_and_conditions?.tac23}</li>
                <li>{strings?.terms_and_conditions?.tac24}</li>
              </ul>
              <p className="text-primary font-semibold">
                {strings?.terms_and_conditions?.tac25}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac26}</p>
              <ul className="list-disc pl-10">
                <li>{strings?.terms_and_conditions?.tac27}</li>
                <li>{strings?.terms_and_conditions?.tac28}</li>
                <li>{strings?.terms_and_conditions?.tac29}</li>
              </ul>
            </div>
            <div className="space-y-2">
              <h3 className="font-semibold  text-primary">
                {strings?.terms_and_conditions?.tac30}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac31}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac32}</p>
            </div>
            <div className="space-y-2">
              <h3 className="font-semibold  text-primary">
                {strings?.terms_and_conditions?.tac33}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac34}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac35}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac36}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac37}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac38}</p>
              <p className="text-sm text-primary font-semibold">
                {strings?.terms_and_conditions?.tac39}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac40}</p>
            </div>
            <div className="space-y-2">
              <h3 className="font-semibold  text-primary">
                {strings?.terms_and_conditions?.tac41}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac42}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac43}</p>
            </div>
            <div className="space-y-2">
              <h3 className="font-semibold  text-primary">
                {strings?.terms_and_conditions?.tac44}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac45}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac46}</p>
              <p className="text-sm">
                {strings?.terms_and_conditions?.tac47}{" "}
                <span className="text-primary font-semibold">
                  {" "}
                  {strings?.terms_and_conditions?.tac48}
                </span>
              </p>
              <ul className="list-disc pl-10">
                <li>{strings?.terms_and_conditions?.tac49}</li>
                <li>{strings?.terms_and_conditions?.tac50}</li>
                <li>{strings?.terms_and_conditions?.tac51}</li>
                <li>{strings?.terms_and_conditions?.tac52}</li>
              </ul>
            </div>
            <div className="space-y-2">
              <h3 className="font-semibold  text-primary">
                {strings?.terms_and_conditions?.tac53}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac54}</p>
              <ul className="list-disc pl-10">
                <li>{strings?.terms_and_conditions?.tac55}</li>
              </ul>
            </div>
          </div>
          <div className="space-y-3">
            <h3 className="text-xl font-semibold  text-primary">
              {strings?.terms_and_conditions?.tac56}
            </h3>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac57}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac58}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac59}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac60}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac61}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac62}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac63}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac64}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac65}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac66}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac67}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac68}</p>
              <ul className="list-disc pl-10">
                <li>{strings?.terms_and_conditions?.tac69}</li>
                <li>{strings?.terms_and_conditions?.tac70}</li>
              </ul>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac71}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac72}</p>
              <ul className="list-disc pl-10">
                <li>{strings?.terms_and_conditions?.tac73}</li>
                <li>{strings?.terms_and_conditions?.tac74}</li>
                <li>{strings?.terms_and_conditions?.tac75}</li>
              </ul>
              <p className="text-sm">{strings?.terms_and_conditions?.tac76}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac77}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac78}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac79}</p>
              <ul className="list-disc pl-10">
                <li>{strings?.terms_and_conditions?.tac80}</li>
              </ul>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac81}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac82}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac83}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac84}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac85}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac86}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac87}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac88}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac89}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac90}</p>
              <ul className="list-disc pl-10">
                <li>{strings?.terms_and_conditions?.tac91}</li>
                <li>{strings?.terms_and_conditions?.tac92}</li>
                <li>{strings?.terms_and_conditions?.tac93}</li>
                <li>{strings?.terms_and_conditions?.tac94}</li>
                <li>{strings?.terms_and_conditions?.tac95}</li>
                <li>{strings?.terms_and_conditions?.tac96}</li>
                <li>{strings?.terms_and_conditions?.tac97}</li>
              </ul>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac98}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac99}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac100}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac101}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac102}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac103}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac104}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac105}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac106}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac107}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac108}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac109}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac110}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac111}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac112}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac113}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac114}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac115}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac116}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac117}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac118}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac119}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac120}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac121}</p>
            </div>
          </div>
          <div className="space-y-3">
            <h3 className="text-lg font-semibold  text-primary">
              {strings?.terms_and_conditions?.tac122}
            </h3>
            <div className="space-y-2">
              <h3 className="font-semibold  text-primary">
                {strings?.terms_and_conditions?.tac123}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac124}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac125}</p>
            </div>
            <div className="space-y-2">
              <h3 className="font-semibold  text-primary">
                {strings?.terms_and_conditions?.tac126}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac127}</p>
            </div>
            <div className="space-y-2">
              <h3 className="font-semibold  text-primary">
                {strings?.terms_and_conditions?.tac128}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac129}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac130}</p>
            </div>
          </div>
          <div className="space-y-3">
            <h3 className="text-lg font-semibold  text-primary">
              {strings?.terms_and_conditions?.tac131}
            </h3>
            <p className="text-sm">{strings?.terms_and_conditions?.tac132}</p>
            <ul className="list-disc pl-10">
              <li>{strings?.terms_and_conditions?.tac133}</li>
              <li>{strings?.terms_and_conditions?.tac134}</li>
              <li>{strings?.terms_and_conditions?.tac135}</li>
              <li>{strings?.terms_and_conditions?.tac136}</li>
            </ul>
            <p className="text-sm">{strings?.terms_and_conditions?.tac137}</p>
          </div>
          <p className="text-xs"> {strings?.terms_and_conditions?.tac138}</p>
          <p className="text-primary font-semibold text-sm">
            {strings?.terms_and_conditions?.tac139}
          </p>
          <p className="text-primary font-semibold text-sm">
            {strings?.terms_and_conditions?.tac140}
          </p>
          <p className="text-primary font-semibold text-sm">
            {strings?.terms_and_conditions?.tac141}
          </p>
          <p className="text-primary font-semibold text-sm">
            {strings?.terms_and_conditions?.tac142}
          </p>
          <p className="text-primary font-semibold text-sm">
            {strings?.terms_and_conditions?.tac143}
          </p>
          <p className="text-sm">{strings?.terms_and_conditions?.tac144}</p>
          <p className="text-primary font-semibold text-xs">
            {strings?.terms_and_conditions?.tac145}
          </p>
          <p className="text-sm text-primary font-semibold">
            {strings?.terms_and_conditions?.tac146}
          </p>
          <p className="text-sm text-primary font-semibold">
            {strings?.terms_and_conditions?.tac147}
          </p>
          <div className="space-y-3">
            <h3 className="text-lg font-semibold text-primary">
              {strings?.terms_and_conditions?.tac148}
            </h3>
            <p className="font-semibold text-primary">
              {strings?.terms_and_conditions?.tac149}
            </p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac150}</p>
            <p className="text-sm font-semibold text-primary">
              {strings?.terms_and_conditions?.tac151}
            </p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac152}</p>
          </div>
          <div className="space-y-3">
            <h3 className="font-semibold text-primary">
              {strings?.terms_and_conditions?.tac153}
            </h3>
            <p className="font-semibold text-primary">
              {strings?.terms_and_conditions?.tac154}
            </p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac155}</p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac156}</p>
            <p className="text-xs text-primary font-semibold">
              {strings?.terms_and_conditions?.tac157}
            </p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac158}</p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac159}</p>
            <ul className="list-disc pl-10">
              <li>
                <span className="text-primary font-semibold">
                  {strings?.terms_and_conditions?.tac160}
                </span>{" "}
                {strings?.terms_and_conditions?.tac161}
              </li>
            </ul>
            <p className="text-primary text-xs font-semibold">
              {strings?.terms_and_conditions?.tac162}
            </p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac163}</p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac164}</p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac165}</p>
            <p className="text-primary text-xs font-semibold">
              {strings?.terms_and_conditions?.tac166}
            </p>
            <p className="text-sm">{strings?.terms_and_conditions?.tac167}</p>
            <ul className="list-disc pl-10">
              <li>{strings?.terms_and_conditions?.tac168}</li>
            </ul>
          </div>
          <div className="space-y-3">
            <h3 className="text-lg font-semibold text-primary">
              {strings?.terms_and_conditions?.tac169}
            </h3>
            <div className="space-y-2">
              <p className="text-primary font-semibold">
                {strings?.terms_and_conditions?.tac170}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac171}</p>
              <p className="text-xs font-semibold text-primary">
                {strings?.terms_and_conditions?.tac172}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac173}</p>
            </div>
          </div>
          <div className="space-y-3">
            <h3 className="text-xl font-semibold text-primary">
              {strings?.terms_and_conditions?.tac174}
            </h3>
            <div className="space-y-2">
              <p className="text-lg font-semibold text-primary">
                {strings?.terms_and_conditions?.tac175}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac176}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac177}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac178}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac179}</p>
            </div>
            <div className="space-y-2">
              <p className="text-lg font-semibold text-primary">
                {strings?.terms_and_conditions?.tac180}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac181}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac182}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac183}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac184}</p>
            </div>
            <div className="space-y-2">
              <p className="font-semibold text-primary">
                {strings?.terms_and_conditions?.tac185}
              </p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac186}</p>
            </div>
            <div className="space-y-3">
              <h3 className="text-lg font-semibold text-primary">
                {strings?.terms_and_conditions?.tac187}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac188}</p>
              <h3 className="text-lg font-semibold text-primary">
                {strings?.terms_and_conditions?.tac189}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac190}</p>
              <ul className="list-disc pl-10">
                <li>{strings?.terms_and_conditions?.tac191}</li>
                <li>{strings?.terms_and_conditions?.tac192}</li>
              </ul>
              <p className="text-sm">{strings?.terms_and_conditions?.tac193}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac194}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac195}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac196}</p>
            </div>
            <div className="space-y-3">
              <h3 className="text-lg font-semibold text-primary">
                {strings?.terms_and_conditions?.tac197}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac198}</p>
              <ul className="list-disc pl-10">
                <li>{strings?.terms_and_conditions?.tac199}</li>
                <li>{strings?.terms_and_conditions?.tac200}</li>
              </ul>
              <p className="text-sm">{strings?.terms_and_conditions?.tac201}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac202}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac203}</p>
              <p className="text-sm">{strings?.terms_and_conditions?.tac204}</p>
            </div>
            <div className="space-y-3">
              <h3 className="text-xl font-semibold text-primary">
                {strings?.terms_and_conditions?.tac205}
              </h3>
              <p className="text-sm">{strings?.terms_and_conditions?.tac206}</p>
              <div className="space-y-2">
                <p className="text-primary font-semibold text-sm">
                  {strings?.terms_and_conditions?.tac207}
                </p>
                <p className="text-sm">
                  {strings?.terms_and_conditions?.tac208}
                </p>
                <p className="text-sm">
                  {strings?.terms_and_conditions?.tac209}
                </p>
                <p className="text-primary font-semibold text-sm">
                  {strings?.terms_and_conditions?.tac210}
                </p>
                <p className="text-sm">
                  {strings?.terms_and_conditions?.tac211}
                </p>
                <p className="text-sm">
                  {strings?.terms_and_conditions?.tac212}
                </p>
                <p className="text-sm">
                  {strings?.terms_and_conditions?.tac213}
                </p>
              </div>
              <div className="space-y-3">
                <h3 className="text-xl font-semibold text-primary">
                  {strings?.terms_and_conditions?.tac214}
                </h3>
                <div className="space-y-2">
                  <p className="text-primary font-semibold text-sm">
                    {strings?.terms_and_conditions?.tac215}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac216}
                  </p>
                </div>
              </div>
              <div className="space-y-3">
                <h3 className="text-xl font-semibold text-primary">
                  {strings?.terms_and_conditions?.tac217}
                </h3>
                <div className="space-y-2">
                  <p className="text-primary font-semibold text-sm">
                    {strings?.terms_and_conditions?.tac218}
                  </p>
                  <p className="text-sm font-semibold">
                    {strings?.terms_and_conditions?.tac219}
                  </p>
                  <p className="text-sm font-semibold">
                    {strings?.terms_and_conditions?.tac220}
                  </p>
                  <p className="text-sm font-semibold">
                    {strings?.terms_and_conditions?.tac221}
                  </p>
                  <p className="text-sm font-semibold">
                    {strings?.terms_and_conditions?.tac223}
                  </p>
                  <p className="text-sm font-semibold">
                    {strings?.terms_and_conditions?.tac224}
                  </p>
                  <p className="text-sm font-semibold text-primary">
                    {strings?.terms_and_conditions?.tac225}
                  </p>
                  <p className="text-sm font-semibold">
                    {strings?.terms_and_conditions?.tac226}
                  </p>
                  <ul className="list-disc font-semibold text-sm pl-10">
                    <li>{strings?.terms_and_conditions?.tac227}</li>
                    <li>{strings?.terms_and_conditions?.tac228}</li>
                    <li> {strings?.terms_and_conditions?.tac229}</li>
                    <li>{strings?.terms_and_conditions?.tac230}</li>
                    <li>{strings?.terms_and_conditions?.tac231}</li>
                    <li>{strings?.terms_and_conditions?.tac232}</li>
                    <li>{strings?.terms_and_conditions?.tac233}</li>
                    <li>{strings?.terms_and_conditions?.tac234}</li>
                    <li>{strings?.terms_and_conditions?.tac235}</li>
                  </ul>
                  <p className="text-sm font-semibold">
                    {strings?.terms_and_conditions?.tac236}
                  </p>
                  <p className="text-sm font-semibold">
                    {strings?.terms_and_conditions?.tac237}
                  </p>
                  <p className="text-sm font-semibold text-primary">
                    {strings?.terms_and_conditions?.tac238}
                  </p>
                  <p className="text-sm font-semibold">
                    {strings?.terms_and_conditions?.tac239}
                  </p>
                  <ul className="list-disc font-semibold text-sm pl-10">
                    <li>{strings?.terms_and_conditions?.tac240}</li>
                    <li>{strings?.terms_and_conditions?.tac241}</li>
                    <li>{strings?.terms_and_conditions?.tac242}</li>
                    <li>{strings?.terms_and_conditions?.tac243}</li>
                    <li>{strings?.terms_and_conditions?.tac244}</li>
                    <li>{strings?.terms_and_conditions?.tac245}</li>
                    <li>{strings?.terms_and_conditions?.tac246}</li>
                  </ul>
                </div>
              </div>
              <div className="space-y-3">
                <h3 className="text-xl font-semibold text-primary">
                  {strings?.terms_and_conditions?.tac247}
                </h3>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac248}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac249}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac250}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac251}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac252}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac253}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac254}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac255}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac256}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac257}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac258}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac259}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac260}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac261}
                  </p>
                  <p className="text-sm">
                    <span className="font-semibold">
                      {strings?.terms_and_conditions?.tac262}
                    </span>
                    {strings?.terms_and_conditions?.tac263}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac264}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac265}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac266}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac267}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac268}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac269}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac270}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac271}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac272}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac273}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac274}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac275}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac276}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac277}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac278}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac279}
                  </p>
                  <p className="text-sm font-semibold text-primary">
                    {strings?.terms_and_conditions?.tac280}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac281}
                  </p>
                  <p className="text-sm font-semibold text-primary">
                    {strings?.terms_and_conditions?.tac282}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac283}
                  </p>
                  <p className="text-sm font-semibold text-primary">
                    {strings?.terms_and_conditions?.tac284}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac285}
                  </p>
                  <p className="text-sm font-semibold text-primary">
                    {strings?.terms_and_conditions?.tac286}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac287}
                  </p>
                  <p className="text-sm font-semibold text-primary">
                    {strings?.terms_and_conditions?.tac288}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac289}
                  </p>
                  <p className="text-sm font-semibold text-primary">
                    {strings?.terms_and_conditions?.tac290}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac291}
                  </p>
                </div>
              </div>
              <div className="space-y-3">
                <h3 className="text-xl font-semibold text-primary">
                  {strings?.terms_and_conditions?.tac292}
                </h3>
                <p className="text-sm">
                  {strings?.terms_and_conditions?.tac293}
                </p>
              </div>
              <div className="space-y-3">
                <h3 className="text-xl font-semibold text-primary">
                  {strings?.terms_and_conditions?.tac294}
                </h3>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac295}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac296}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac297}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac298}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac299}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac300}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac301}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac302}{" "}
                    <Link
                      to={
                        "https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
                      }
                      className="text-primary"
                    >
                      {strings?.terms_and_conditions?.tac303}
                    </Link>
                  </p>
                  <p className="text-sm font-semibold">
                    {strings?.terms_and_conditions?.tac304}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac305}
                  </p>
                  <p className="text-primary font-semibold text-sm">
                    {strings?.terms_and_conditions?.tac306}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac307}
                  </p>
                  <ul className="list-disc text-sm pl-10">
                    <li>
                      {strings?.terms_and_conditions?.tac308}{" "}
                      <Link
                        className="text-primary"
                        to={
                          "https://www.economie.gouv.fr/mediation-conso/mediateurs-references"
                        }
                      >
                        {strings?.terms_and_conditions?.tac309}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="space-y-3">
                <h3 className="text-xl font-semibold text-primary">
                  {strings?.terms_and_conditions?.tac310}
                </h3>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac311}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac312}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac313}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac314}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac315}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac316}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac317}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac318}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac319}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac320}
                  </p>
                  <ul className="list-disc text-sm pl-10">
                    <li>{strings?.terms_and_conditions?.tac321}</li>
                    <li>{strings?.terms_and_conditions?.tac322}</li>
                  </ul>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac323}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac324}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac325}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac326}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac327}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac328}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac329}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac330}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac331}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac332}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac333}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac334}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac335}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac336}
                  </p>
                </div>
                <div className="space-y-2">
                  <p className="text-primary font-semibold">
                    {strings?.terms_and_conditions?.tac337}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac338}
                  </p>
                  <p className="text-sm">
                    {strings?.terms_and_conditions?.tac339}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
