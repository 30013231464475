import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import {
  BannerPhone,
  ScreenLP01,
  ScreenLP02,
  ScreenLP03,
  ScreenLP04,
  ScreenLP05,
  ScreenLP06,
  ScreenLP07,
  ScreenLP08,
  ScreenLP09,
  ScreenLP10,
  ScreenLP11,
  ScreenLPMobile01,
  ScreenLPMobile02,
  ScreenLPMobile03,
  ScreenLPMobile04,
  ScreenLPMobile05,
  ScreenLPMobile06,
  ScreenLPMobile07,
  ScreenLPMobile08,
  ScreenLPMobile09,
  ScreenLPMobile10,
  ScreenLPMobile11,
} from "../../assets";
import { AppStoreIcon, PlayStoreIcon } from "../../assets/svg/AllSvg";
import { Autoplay } from "swiper/modules";
import strings from "../../components/localization";
import FormContent from "./FormContent";
import Vision from "./vision";
import { Link } from "react-router-dom";

const Home = () => {
  const email = "hello@ebbiapp.com";
  const subject = "Let's Talk with Ebbi";
  const body = `Thank you for showing interest, We will provide you access of our app on your phone soon

Thank you for you patience`;
  const handleClick = () => {
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
  };
  return (
    <>
      <div className="relative h-screen before:absolute 2xl:before:translate-y-5 xl:before:translate-y-7.5 sm:before:translate-y-7 before:translate-y-7 before:bottom-0 before:z-10 2xl:before:rotate-1 2xl:before:-translate-x-0 before:-translate-x-0.5 sm:before:rotate-2 before:rotate-3 before:w-full before:h-14 before:bg-primary">
        <Swiper
          className="md:block hidden h-full"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Autoplay]}
          spaceBetween={25}
          slidesPerView={1}
        >
          {[
            ScreenLP01,
            ScreenLP02,
            ScreenLP03,
            ScreenLP04,
            ScreenLP11,
            ScreenLP05,
            ScreenLP06,
            ScreenLP07,
            ScreenLP08,
            ScreenLP09,
            ScreenLP10,
          ].map((e, index) => (
            <SwiperSlide className="w-full h-full" key={index}>
              <div className="w-full h-full">
                <img
                  src={e}
                  className="w-full h-full 2xl:object-top object-cover"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* teblet ui start */}
        <Swiper
          className="md:hidden h-full"
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          loop={true}
          modules={[Autoplay]}
          spaceBetween={25}
          slidesPerView={1}
        >
          {[
            ScreenLPMobile01,
            ScreenLPMobile02,
            ScreenLPMobile03,
            ScreenLPMobile04,
            ScreenLPMobile11,
            ScreenLPMobile05,
            ScreenLPMobile06,
            ScreenLPMobile07,
            ScreenLPMobile08,
            ScreenLPMobile09,
            ScreenLPMobile10,
          ].map((e, index) => (
            <SwiperSlide className="w-full h-full" key={index}>
              <div className="w-full h-full">
                <img
                  src={e}
                  className="w-full h-full 2xl:object-top object-cover"
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* tablet ui end */}
        <div className="md:flex hidden flex-col items-center justify-center absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 z-10">
          <div className="flex space-x-5 2xl:translate-x-[25%] xl:translate-x-[27%] translate-x-[25%] lg:mb-5 mb-3">
            <div>
              <img
                src={BannerPhone}
                className="2xl:h-[600px] xl:h-[500px] lg:h-96 h-52"
              />
            </div>
            <div className="flex flex-col justify-between 2xl:pt-20 lg:pt-10 pt-5 2xl:py-12 xl:py-8 lg:py-6 py-3">
              <div className="space-y-1 2xl:text-2xl xl:text-lg lg:text-base text-sm font-rajdhani  text-[#FEFCF6] 2xl:max-w-[355px] 2xl:min-w-[355px] xl:max-w-[265px] xl:min-w-[265px] lg:max-w-[240px] lg:min-w-[240px]">
                <p className="font-medium w-full">{strings?.text1}</p>
                <div
                  className="xl:leading-6 leading-5 w-full font-medium"
                  dangerouslySetInnerHTML={{ __html: strings?.text2 }}
                ></div>
              </div>
              {/* <div
                className="2xl:text-xs font-thin text-[10px] font-dmmonoFont max-w-[300px] leading-none"
                dangerouslySetInnerHTML={{ __html: strings?.text3 }}
              ></div> */}
            </div>
          </div>
          <div>
            <div className="flex justify-center gap-2 2xl:pl-0 xl:pl-12 lg:pr-7 md:pr-14 md:pl-2">
              <div className="xl:w-24 lg:w-20 w-12">
                <Link
                  target="_blank"
                  to={"https://apps.apple.com/in/app/ebbi/id6705127544"}
                >
                  <AppStoreIcon className="w-full" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* tablet ui start */}
        <div className="md:hidden absolute top-0 left-0 z-10 h-full w-full">
          <div className="container px-8 mx-auto py-24 flex flex-col justify-between text-center h-full">
            <div className="space-y-3 2xl:text-2xl text-[#FAF4EB] text-xl font-rajdhani font-medium max-w-50 mx-auto">
              <p>{strings?.text1}</p>
              <div
                className="leading-[1.15]"
                dangerouslySetInnerHTML={{ __html: strings?.text2 }}
              ></div>
            </div>
            <div className="font-rajdhani max-w-xs w-full mx-auto">
              {/* <div
                className="text-xs text-[#FAF4EB] font-semibold mb-2"
                dangerouslySetInnerHTML={{ __html: strings?.text3 }}
              ></div> */}
              <div className="w-full block">
                <button
                  className="bg-primary block font-spaceGrotesk rounded-full font-bold text-2xl py-5 px-12 mb-3 w-full"
                  onClick={handleClick}
                >
                  let's start
                </button>
              </div>
              <div className="flex justify-center gap-1">
                <div className="w-24">
                  <Link
                    target="_blank"
                    to={"https://apps.apple.com/in/app/ebbi/id6705127544"}
                  >
                    <AppStoreIcon className="w-full" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* tablet ui end */}
      </div>
      <FormContent />
      <Vision />
    </>
  );
};

export default Home;
