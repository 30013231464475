import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

const MainLayout = ({ selectedLanguage, setSelectedLanguage }) => {
  const location = useLocation();
  return (
    <div>
      <div className={`text-lightBackgroundDefault`}>
        {/* {location.pathname === "/" ? (
          
        ) : null} */}
        <Header
            setSelectedLanguage={setSelectedLanguage}
            selectedLanguage={selectedLanguage}
          />
        <Outlet />
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
