import React from "react";
import { Link } from "react-router-dom";
import strings from "../../components/localization";
import { Logo } from "../../assets/svg/AllSvg";

const PrivacyPolicy = () => {
  return (
    <div className="">
      <div className="text-black font-rajdhani py-5 mt-4">
        <div className="container sm:px-6 px-4 mx-auto space-y-5">
          <Link className="inline-block" to={"/"}>
            <Logo fillClass={"fill-primary"} />
          </Link>
          <h1 className="text-4xl font-semibold text-primary  mb-4">
          {strings?.privacy_policy?.prp}
          </h1>
          <p className="text-sm">
          {strings?.privacy_policy?.prp1}
          </p>
          <p className="text-sm">
            {strings?.privacy_policy?.prp2}
          </p>
          <div className="space-y-2">
            <h3 className="text-xl font-semibold  text-primary">
            {strings?.privacy_policy?.prp3}
            </h3>
            <div className="text-sm">
              <p>{strings?.privacy_policy?.prp4}</p>
              <p>{strings?.privacy_policy?.prp5}</p>
              <p>{strings?.privacy_policy?.prp6}</p>
              <p>{strings?.privacy_policy?.prp7}</p>
              <p>{strings?.privacy_policy?.prp8}</p>
              <p>{strings?.privacy_policy?.prp9}</p>
            </div>
            <p className="font-semibold  text-primary">
            {strings?.privacy_policy?.prp10}{" "}
              <span className="text-black font-normal">
              {strings?.privacy_policy?.prp11}
              </span>
            </p>
          </div>

          <div className="space-y-3">
            <div className="space-y-2">
              <h2 className="text-2xl font-semibold  text-primary mb-4">
              {strings?.privacy_policy?.prp12}
              </h2>
              <p className="text-sm">
              {strings?.privacy_policy?.prp13}
              </p>
              <p className="text-sm">
              {strings?.privacy_policy?.prp14}
              </p>
              <p className="text-sm">
              {strings?.privacy_policy?.prp15}
              </p>
              <p className="text-sm">
              {strings?.privacy_policy?.prp16}
              </p>
            </div>
            <div className="space-y-3">
              <h2 className="text-2xl font-semibold  text-primary mb-4">
              {strings?.privacy_policy?.prp17}
              </h2>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp18}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp19}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp20}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">{strings?.privacy_policy?.prp21}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp22}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp23}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp24}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp25}
                </p>
              </div>
            </div>
            <div className="space-y-3">
              <h2 className="text-2xl font-semibold  text-primary mb-4">
              {strings?.privacy_policy?.prp26}
              </h2>
              <p className="text-sm">
              {strings?.privacy_policy?.prp27}
              </p>
              <p className="text-sm">
              {strings?.privacy_policy?.prp28}
              </p>
            </div>
            <div className="space-y-3">
              <h2 className="text-2xl font-semibold  text-primary mb-4">
              {strings?.privacy_policy?.prp29}
              </h2>
              <p className="text-sm">
              {strings?.privacy_policy?.prp30}
              </p>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp31}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp32}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp33}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp34}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp35}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp36}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp37}{" "}
                  <Link
                    className="text-primary"
                    to={"https://www.iubenda.com/privacy-policy/36700132"}
                  >
                    {strings?.privacy_policy?.prp38}
                  </Link>
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp39}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp40}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp41}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp42}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp43}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp44}{" "}
                  <Link
                    className="text-primary"
                    to={"https://www.iubenda.com/privacy-policy/36700132"}
                  >
                    {strings?.privacy_policy?.prp45}
                  </Link>
                </p>
              </div>
            </div>
            <div className="space-y-3">
              <h2 className="text-2xl font-semibold  text-primary mb-4">
              {strings?.privacy_policy?.prp46}
              </h2>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp47}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp48}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp49}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp50}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp51}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp52}
                </p>
              </div>
            </div>
            <div className="space-y-3">
              <h2 className="text-2xl font-semibold  text-primary mb-4">
              {strings?.privacy_policy?.prp53}
              </h2>
              <p className="text-sm">
              {strings?.privacy_policy?.prp54}
              </p>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp55}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp56}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp57}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp58}
                  <Link to={"https://business.safety.google/privacy/"}>
                    {" "}
                    {strings?.privacy_policy?.prp59}
                  </Link>
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp60}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp61}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp62}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp63}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp64}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp65}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp66}
                  <Link
                    className="text-primary"
                    to={"https://aws.amazon.com/compliance/data-privacy-faq/"}
                  >
                    {" "}
                    {strings?.privacy_policy?.prp67}
                  </Link>
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp68}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp69}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp70}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp71}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp72}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp73}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp74}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp75}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp76}
                  <Link
                    className="text-primary"
                    to={"https://www.facebook.com/privacy/policy/"}
                  >
                    {" "}
                    {strings?.privacy_policy?.prp77}
                  </Link>
                  <Link
                    className="text-primary"
                    to={"https://www.facebook.com/help/247395082112892"}
                  >
                    {" "}
                    {strings?.privacy_policy?.prp78}
                  </Link>
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp79}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp80}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp81}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp82}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp83}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp84}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp85}
                  <Link
                    to={"https://support.apple.com/en-gb/108971"}
                    className="text-primary"
                  >
                    {strings?.privacy_policy?.prp86}
                  </Link>
                  {strings?.privacy_policy?.prp87}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp88}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp89}{" "}
                  <Link
                    to={"https://www.apple.com/legal/privacy/"}
                    className="text-primary"
                  >
                    {strings?.privacy_policy?.prp90}
                  </Link>{" "}
                  {strings?.privacy_policy?.prp91}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp92}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp93}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp94}
                  <Link
                    to={"https://support.google.com/accounts/answer/6078260"}
                    className="text-primary"
                  >
                    {strings?.privacy_policy?.prp95}
                  </Link>{" "}
                  {strings?.privacy_policy?.prp96}
                </p>
                <p className="text-sm">{strings?.privacy_policy?.prp97}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp98}
                  <Link
                    to={"https://policies.google.com/privacy"}
                    className="text-primary"
                  >
                    {strings?.privacy_policy?.prp99}
                  </Link>
                  {strings?.privacy_policy?.prp100}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp101}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp102}
                </p>
                <p className="text-sm">{strings?.privacy_policy?.prp103}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp104}
                  <Link
                    to={"https://www.ionos.com/terms-gtc/privacy-policy"}
                    className="text-primary"
                  >
                    {strings?.privacy_policy?.prp105}
                  </Link>{" "}
                  {strings?.privacy_policy?.prp106}
                </p>
              </div>
              <div className="space-y-2">
                <h2 className="text-2xl font-semibold  text-primary mb-4">
                {strings?.privacy_policy?.prp107}
                </h2>
                <p className="text-sm">
                {strings?.privacy_policy?.prp108}
                  <Link
                    className="text-primary"
                    to={
                      "https://www.iubenda.com/privacy-policy/50279361/cookie-policy"
                    }
                  >
                    {strings?.privacy_policy?.prp109}
                  </Link>
                  {strings?.privacy_policy?.prp110}
                </p>
              </div>
            </div>
            <div className="space-y-3">
              <h2 className="text-2xl font-semibold  text-primary mb-4">
              {strings?.privacy_policy?.prp111}
              </h2>

              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp112}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp113}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp114}
                </p>
                <ul className="list-disc text-sm pl-10">
                  <li>
                  {strings?.privacy_policy?.prp115}
                  </li>
                  <li>
                  {strings?.privacy_policy?.prp116}
                  </li>
                  <li>
                  {strings?.privacy_policy?.prp117}
                  </li>
                  <li>
                  {strings?.privacy_policy?.prp118}
                  </li>
                  <li>
                  {strings?.privacy_policy?.prp119}
                  </li>
                </ul>
                <p className="text-sm">
                {strings?.privacy_policy?.prp120}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp121}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp122}
                </p>
                <p className="text-sm">{strings?.privacy_policy?.prp123}</p>
                <ul className="list-disc text-sm pl-10">
                  <li>
                  {strings?.privacy_policy?.prp124}
                  </li>
                  <li>
                  {strings?.privacy_policy?.prp125}
                  </li>
                </ul>
                <p className="text-sm">
                {strings?.privacy_policy?.prp126}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp127}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp128}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp129}
                </p>
                <ul className="list-disc text-sm pl-10">
                  <li>
                    <span className="font-semibold">
                    {strings?.privacy_policy?.prp130}
                    </span>
                    {strings?.privacy_policy?.prp131}
                  </li>
                  <li>
                    <span className="font-semibold">
                    {strings?.privacy_policy?.prp132}
                    </span>
                    {strings?.privacy_policy?.prp133}
                  </li>
                  <li>
                    <span className="font-semibold">{strings?.privacy_policy?.prp134}</span>
                    {strings?.privacy_policy?.prp135}
                  </li>
                  <li>
                    <span className="font-semibold">
                    {strings?.privacy_policy?.prp136}
                    </span>
                    {strings?.privacy_policy?.prp137}
                  </li>
                  <li>
                    <span className="font-semibold">
                    {strings?.privacy_policy?.prp138}.
                    </span>
                    {strings?.privacy_policy?.prp139}
                  </li>
                  <li>
                    <span className="font-semibold">
                    {strings?.privacy_policy?.prp140}
                    </span>
                    {strings?.privacy_policy?.prp141}
                  </li>
                  <li>
                    <span className="font-semibold">
                    {strings?.privacy_policy?.prp142}
                    </span>
                    {strings?.privacy_policy?.prp143}
                  </li>
                  <li>
                    <span className="font-semibold">{strings?.privacy_policy?.prp144}</span>
                    {strings?.privacy_policy?.prp145}
                  </li>
                </ul>
                <p className="text-sm">
                {strings?.privacy_policy?.prp146}
                </p>
                <p className="text-sm font-semibold text-primary">
                {strings?.privacy_policy?.prp147}
                </p>
                <p className="text-sm font-semibold">
                {strings?.privacy_policy?.prp148}
                </p>
                <p className="text-sm font-semibold">
                {strings?.privacy_policy?.prp149}
                </p>
                <p className="text-sm font-semibold text-primary">
                {strings?.privacy_policy?.prp150}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp151}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp152}
                </p>
                <p className="font-semibold text-primary">
                {strings?.privacy_policy?.prp153}
                </p>
                <p className="text-sm font-semibold text-primary">
                {strings?.privacy_policy?.prp154}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp155}
                </p>
              </div>
              <div className="space-y-2">
                <p className="text-xl font-semibold text-primary">
                {strings?.privacy_policy?.prp156}
                </p>
                <p className="font-semibold text-primary">{strings?.privacy_policy?.prp157}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp158}
                </p>
              </div>
              <div className="space-y-2">
                <p className="font-semibold text-primary">
                {strings?.privacy_policy?.prp159}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp160}
                </p>
              </div>
              <div className="space-y-2">
                <p className="font-semibold text-primary">
                {strings?.privacy_policy?.prp161}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp162}
                </p>
              </div>
              <div className="space-y-2">
                <p className="font-semibold text-primary">
                {strings?.privacy_policy?.prp163}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp164}
                </p>
              </div>
              <div className="space-y-2">
                <p className="font-semibold text-primary">
                {strings?.privacy_policy?.prp165}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp166}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp167}
                </p>
              </div>
              <p className="text-xl font-semibold text-primary">
              {strings?.privacy_policy?.prp168}
              </p>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">
                {strings?.privacy_policy?.prp169}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp170}
                </p>
              </div>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">{strings?.privacy_policy?.prp171}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp172}
                </p>
              </div>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">{strings?.privacy_policy?.prp173}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp174}
                </p>
              </div>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">{strings?.privacy_policy?.prp175}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp176}
                </p>
              </div>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">
                {strings?.privacy_policy?.prp177}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp178}
                </p>
              </div>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">
                {strings?.privacy_policy?.prp179}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp180}
                </p>
              </div>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">{strings?.privacy_policy?.prp181}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp182}
                </p>
              </div>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">{strings?.privacy_policy?.prp183}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp184}
                </p>
              </div>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">
                {strings?.privacy_policy?.prp185}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp186}
                </p>
              </div>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">{strings?.privacy_policy?.prp187}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp188}
                </p>
              </div>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">{strings?.privacy_policy?.prp189}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp190}
                </p>
              </div>
              <div className="space-y-2">
                <p className=" font-semibold text-primary">{strings?.privacy_policy?.prp191}</p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp192}
                </p>
                <p className="text-sm">
                {strings?.privacy_policy?.prp193}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
